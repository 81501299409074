/* index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .all-\[unset\] {
    all: unset;
  }
}

@layer utilities {
  .delay-100 { animation-delay: 0.1s; }
  .delay-200 { animation-delay: 0.2s; }
  .delay-300 { animation-delay: 0.3s; }
  .delay-400 { animation-delay: 0.4s; }
  .delay-500 { animation-delay: 0.5s; }
  .delay-600 { animation-delay: 0.6s; }
  .delay-700 { animation-delay: 0.7s; }
  .delay-800 { animation-delay: 0.8s; }
  .delay-900 { animation-delay: 0.9s; }
}

:root {
  --color-background-brand-default: var(--color-primitives-brand-800);
  --color-background-brand-hover: var(--color-primitives-brand-900);
  --color-border-brand-default: var(--color-primitives-brand-800);
  --color-primitives-brand-100: rgba(245, 245, 245, 1);
  --color-primitives-brand-300: rgba(217, 217, 217, 1);
  --color-primitives-brand-800: rgba(44, 44, 44, 1);
  --color-primitives-brand-900: rgba(30, 30, 30, 1);
  --color-text-brand-on-brand: var(--color-primitives-brand-100);
  --single-line-body-base-font-family: "Inter", Helvetica;
  --single-line-body-base-font-size: 16px;
  --single-line-body-base-font-style: normal;
  --single-line-body-base-font-weight: 400;
  --single-line-body-base-letter-spacing: 0px;
  --single-line-body-base-line-height: 100%;
  --size-radius-200: 8px;
  --size-space-200: 8px;
  --size-space-300: 12px;
  --system-colors-overlays-default: rgba(0, 0, 0, 0.2);
}

[data-color-mode="SDS-light"] {
  --color-background-brand-default: var(--color-primitives-brand-800);
  --color-background-brand-hover: var(--color-primitives-brand-900);
  --color-border-brand-default: var(--color-primitives-brand-800);
  --color-text-brand-on-brand: var(--color-primitives-brand-100);
}

[data-color-mode="SDS-dark"] {
  --color-background-brand-default: var(--color-primitives-brand-100);
  --color-background-brand-hover: var(--color-primitives-brand-300);
  --color-border-brand-default: var(--color-primitives-brand-100);
  --color-text-brand-on-brand: var(--color-primitives-brand-900);
}

[data-system-colors-mode="light"] {
  --system-colors-overlays-default: rgba(0, 0, 0, 0.2);
}

[data-system-colors-mode="dark"] {
  --system-colors-overlays-default: rgba(0, 0, 0, 0.49);
}

[data-system-colors-mode="dark-elevated"] {
  --system-colors-overlays-default: rgba(0, 0, 0, 0.49);
}
