.App {
  overflow-x: hidden;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  margin: 0;
  align-items: center;
  height: 100%;
  font-family: "SpoqaHanSansNeo-Regular", sans-serif;
}

@font-face {
  font-family: "SpoqaHanSansNeo-Regular";
  src: url("https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_2108@1.1/SpoqaHanSansNeo-Regular.woff")
    format("woff");
  font-weight: normal;
  font-style: normal;
}

body {
  font-family: "SpoqaHanSansNeo-Regular";
}


.txt span {
  position: relative;
  background-image: linear-gradient(#0064ff, #202632);
  background-clip: text;
  text-fill-color: transparent;
  animation: txtup 4s infinite linear;
}

/* src/App.css */
@keyframes txtup {
  0% {
    top: 0;
  }
  10% {
    top: -0.7rem;
  }
  20% {
    top: 0;
  }
}

.txt span:nth-of-type(1) {
  animation-delay: 0s;
}
.txt span:nth-of-type(2) {
  animation-delay: 0.1s;
}
.txt span:nth-of-type(3) {
  animation-delay: 0.2s;
}
.txt span:nth-of-type(4) {
  animation-delay: 0.3s;
}
.txt span:nth-of-type(5) {
  animation-delay: 0.4s;
}
.txt span:nth-of-type(6) {
  animation-delay: 0.5s;
}
.txt span:nth-of-type(7) {
  animation-delay: 0.6s;
}

/* Hide splash screen by default */
.splash-screen {
  display: none;
}

/* Show splash screen only on mobile devices */
@media (max-width: 768px) {
  .splash-screen {
    display: flex;
  }
}